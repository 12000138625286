const disruptionURL = process.env.NEXT_PUBLIC_DISRUPTION_API_URL;
const disruptionKey = process.env.NEXT_PUBLIC_DISRUPTION_API_KEY;

function getCSRFTokenFromCookie() {
  return document.cookie
    .split(";")
    .find((part) => part.trim().startsWith("csrftoken="))
    ?.split("=")[1];
}

export default function fetcher(path, options) {
  if (path.startsWith("/disruptions/")) {
    const keyPrefix = path.includes("?") ? "&" : "?";
    return fetch(
      `${disruptionURL}${path}${keyPrefix}key=${disruptionKey}`,
      options,
    ).then((response) => response.json());
  }

  return fetch(`/api/v1${path}`, {
    ...options,
    headers: {
      "X-CSRFToken": getCSRFTokenFromCookie(),
      "Content-Type": "application/json",
      ...(options?.headers || {}),
    },
  }).then(async (res) => {
    if (res.status !== 204) {
      if (!res.ok) {
        let message = res.statusText;
        try {
          message = await res.json();
        } catch (err) {
          // console.log(err);
        }
        throw new Error(message);
      }

      return res.json();
    }
  });
}
